import axios from "axios";
import axiosInstance from "../../../../utils/axios";
import urls from "../../../../utils/urls";
import {
  ADD_CUSTOMERS,
  ADD_ONE_OFF_CUSTOMERS,
  ADD_ONE_OFF_CUSTOMERS_FAILED,
  ADD_ONE_OFF_CUSTOMERS_START,
  ALL_ONE_OFF_CUSTOMERS,
  ALL_ONE_OFF_CUSTOMERS_FAILED,
  ALL_ONE_OFF_CUSTOMERS_START,
  CLEAR_ONE_OFF_CUSTOMERS,
  COUNTRY_CUSTOMERS,
  CURRENT_CUSTOMER,
  DELETE_CUSTOMERS,
  EDIT_CUSTOMERS,
  SET_LOADING,
  SET_TOTAL_PAGES,
  UPDATE_CUSTOMER_STATUS,
  VIEW_ALL_DISTRIBUTOR_CUSTOMERS,
  VIEW_ALL_DISTRIBUTOR_CUSTOMER_ORDERS,
  VIEW_MINI_ADMIN_DISTRIBUTOR_CUSTOMERS,
  VIEW_SINGLE_DISTRIBUTOR_CUSTOMER,
  VIEW_SINGLE_DISTRIBUTOR_CUSTOMER_ORDER,
} from "./types";

const { customerNet, customerNetBase, orderNet } = urls;

export const addCustomers = (details) => (dispatch) =>
  axiosInstance
    .post(`${customerNet}register`, details)
    .then((response) => {
      return dispatch({
        type: ADD_CUSTOMERS,
        customer: response,
      });
    })
    .catch((error) => Promise.reject(error.response.data.msg));

export const editCustomers = (details, id) => (dispatch) => {
  axiosInstance
    .patch(`${customerNet}updatecustomer/${id}`, details)
    .then((response) => {
      return dispatch({
        type: EDIT_CUSTOMERS,
        editCustomer: true,
        loading: false,
      });
    })
    .catch((error) => {
      return dispatch({
        type: EDIT_CUSTOMERS,
        editCustomer: false,
        loading: false,
      });
    });
};

export const deleteCustomers = (details) => (dispatch) => {
  axiosInstance
    .delete(customerNetBase, details)
    .then((response) => {
      return dispatch({
        type: DELETE_CUSTOMERS,
        payload: response,
      });
    })
    .catch((error) => {
      return;
    });
};
export const downloadCustomers = async (country) => {
  try {
    const res = await axiosInstance.get(
      `${customerNetBase}/customerDownload?country=${country}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getCountryCustomers =
  (country, currentPage, search, status, customerType, sortDate, sortName) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${customerNetBase}/getbycountry/${country}?pageNo=${currentPage}&pageSize=10&customerType=${customerType}&status=${status}&search=${search}&sortDate=${sortDate}&sortName=${sortName}`
      );
      const { data } = response;
      return dispatch({
        type: COUNTRY_CUSTOMERS,
        paginated_customers: data.result,
        customers_count: data.count,
      });
    } catch (error) {
      return;
    }
  };

export const getMiniAdminCustomers =
  (distCodes, currentPage, pageSize = "10", customerType, search) =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${customerNetBase}/getbydistributor-array?pageNo=${currentPage}&pageSize=${pageSize}&customerType=${customerType}&search=${search}`,
        distCodes
      );
      const { data } = response;
      return dispatch({
        type: VIEW_MINI_ADMIN_DISTRIBUTOR_CUSTOMERS,
        all_customers: data.result,
        count: data.count,
      });
    } catch (error) {
      return;
    }
  };

export const updateCustomerStatus = (customerId, value) => async (dispatch) => {
  try {
    const response = await axiosInstance.patch(
      `${customerNet}updatecustomer/status/${customerId}`,
      value
    );
    const { data } = response;
    return dispatch({
      type: UPDATE_CUSTOMER_STATUS,
      updatedStaus: data,
    });
  } catch (error) {
    return;
  }
};

export const getAllDistributorCustomers =
  (
    code,
    pageNo,
    pageSize,
    query = "",
    status = "",
    customerType = "",
    sortDate = "",
    sortName = "",
  ) =>
  async (dispatch) => {
    try {
      const response = await axios.get(
        ` ${process.env.REACT_APP_BASE_URL}/customer/customer/distributor/${code}?pageNo=${pageNo}&pageSize=${pageSize}&search=${query}&customerType=${customerType}&status=${status}&sortName=${sortName}&sortDate=${sortDate}`
      );
      const { data } = response;
      return dispatch({
        type: VIEW_ALL_DISTRIBUTOR_CUSTOMERS,
        all_customers: data.result,
        count: data.count,
      });
    } catch (error) {
      return;
    }
  };

export const getAllDistributorCustomerOrders =
  (customerId) => async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${orderNet}GetOrderByBuyerCompanyId/${customerId}`
      );
      const { data } = response;
      return dispatch({
        type: VIEW_ALL_DISTRIBUTOR_CUSTOMER_ORDERS,
        all_dist_customers_orders: data.result,
      });
    } catch (error) {
      return;
    }
  };

export const getSingleDistributorCustomers =
  (id, country) => async (dispatch) => {
    axiosInstance
      .post(`${customerNetBase}/salesforce/${id}`, { country: country })
      .then((response) => {
        return dispatch({
          type: VIEW_SINGLE_DISTRIBUTOR_CUSTOMER,
          customer: response.data.result,
        });
      })
      .catch((error) => {
        return;
      });
  };

export const getSingleDistributorCustomerOrder =
  (buyercode, sellercode, pageSize = "", pageNumber = 1) =>
  async (dispatch) => {
    axiosInstance
      .get(
        `${orderNet}/GetOrder/GetOrderByBuyerDistributorId/${buyercode}/${sellercode}?pageSize=${pageSize}&pageNumber=${pageNumber}`
      )
      .then((response) => {
        return dispatch({
          type: VIEW_SINGLE_DISTRIBUTOR_CUSTOMER_ORDER,
          orders: response.data.order,
          total_pages: response.data.totalOrders,
        });
      })
      .catch((error) => {
        return;
      });
  };

export const getPaginatedSingleDistributorCustomerOrder =
  (buyercode, sellercode, currentPage, pageSize) => async (dispatch) => {
    axiosInstance
      .get(
        `${orderNet}/GetOrder/GetOrderByBuyerDistributorId/${buyercode}/${sellercode}?pageNumber=${currentPage}&pageSize=${pageSize}`
      )
      .then((response) => {
        return dispatch({
          type: VIEW_SINGLE_DISTRIBUTOR_CUSTOMER_ORDER,
          orders: response.data.order,
          total_pages: response.data.totalOrders,
        });
      })
      .catch((error) => {
        return;
      });
  };

const loadAllOneOffCustomers = () => {
  return {
    type: ALL_ONE_OFF_CUSTOMERS_START,
  };
};
const allOneOffCustomers = (payload) => {
  return {
    type: ALL_ONE_OFF_CUSTOMERS,
    payload,
  };
};
const allOneOffCustomersFailed = (payload) => {
  return {
    type: ALL_ONE_OFF_CUSTOMERS_FAILED,
    payload,
  };
};

export const getAllOneOffCustomers = (country, pageNo, pageSize, query) => {
  return async (dispatch) => {
    dispatch(loadAllOneOffCustomers());
    try {
      const res = await axiosInstance.get(
        `${customerNet}oneoff-customer/getbycountry/${country}?pageNo=${pageNo}&pageSize=${pageSize}&search=${query}`
      );
      dispatch(allOneOffCustomers(res.data));
    } catch (err) {
      dispatch(allOneOffCustomersFailed("Internal sever error"));
    }
  };
};

const loadAddOneOffCustomers = () => {
  return {
    type: ADD_ONE_OFF_CUSTOMERS_START,
  };
};
const addOneOffCustomer = (payload) => {
  return {
    type: ADD_ONE_OFF_CUSTOMERS,
    payload,
  };
};
const addOneOffCustomerFailed = (payload) => {
  return {
    type: ADD_ONE_OFF_CUSTOMERS_FAILED,
    payload,
  };
};

export const updateCustomer = (action) => (dispatch) => {
  dispatch({
    type: CURRENT_CUSTOMER,
    customer: action,
  });
};

export const setTotalPages = (action) => (dispatch) => {
  dispatch({
    type: SET_TOTAL_PAGES,
    total_pages: action,
  });
};

export const setEditCustomerToDefault = (action) => (dispatch) => {
  return dispatch({
    type: EDIT_CUSTOMERS,
    editCustomer: false,
    loading: false,
  });
};

export const addOneOffCustomerAction = (customerName, phoneNumber, country) => {
  return async (dispatch) => {
    dispatch(loadAddOneOffCustomers());
    try {
      const res = await axiosInstance.post(
        `${customerNet}oneoff-customer/register`,
        {
          customerName,
          phoneNumber,
          country,
        }
      );
      dispatch(addOneOffCustomer(res.data));
    } catch (err) {
      dispatch(addOneOffCustomerFailed(err.response.data));
    }
  };
};

export const clearOneOff = () => async (dispatch) => {
  dispatch({ type: CLEAR_ONE_OFF_CUSTOMERS });
};

export const uploadCSVCustomerList = (file, email) => {
  const formData = new FormData();
  formData.append("csv", file);
  formData.append("email", email);
  return async () => {
    try {
      await axiosInstance.post(
        `${customerNet}uploadcsv/v2/customer-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data'",
          },
        }
      );
      return {
        isError: false,
      };
    } catch (err) {
      const { reason: reasons, message } = err.response.data || {};
      return {
        isError: true,
        reasons,
        message,
      };
    }
  };
};

export const getCustomerBySalesforceId = async (buyerCompanyId, country) => {
  try {
    const res = await axiosInstance.post(
      `${customerNet}customer/salesforce/${buyerCompanyId}`,
      {
        country: country,
      }
    );
    return res?.data?.result ? res?.data?.result[0] : {};
  } catch (err) {
    return err;
  }
};

export const setLoadingToDefault = () => async (dispatch) => {
  return dispatch({
    type: SET_LOADING,
    loading: true,
  });
};
